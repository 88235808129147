
<template>
  <div class="w-full min-h-[100vh] bg-[#F4F8F9] overflow-hidden">
      <div class="w-full fixed left-0 top-0 z-[99]">
        <div class="w-full">
          <div class="w-full h-[3.125rem] bg-[#3D8CFF] flex justify-between items-center">
            <img src="@/assets/img/m/logo.png" class="w-[11.875rem] h-[.9375rem] ml-[.9375rem]"/>
            <div class="w-[1.375rem] h-[1.375rem] mr-[1.25rem]"  @click="activebtn()">
              <img src="@/assets/img/m/nav.png" class="w-full h-full" v-if="!isnav"/>
              <img src="@/assets/img/m/close.png" class="w-full h-full" v-if="isnav"/>
            </div>
          </div>
          <div class="navlist w-full bg-[#F4F8F9]" v-if="isnav">
            <ul class="w-[90%] mx-auto">
              <li class="borderli text-[1rem] text-[#000000] h-[4.25rem] leading-[4.25rem] font-[500]" :style="{opacity:navIndex == index?'50%':'100%'}" v-for="(item,index) in navdata" :key="index" @click="activeNav(index)">{{ item.name }}</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- page -->
      <div class="w-full mt-[3.125rem]">
        <RouterView />
      </div>
      <!-- 底部信息 -->
      <div class="w-full h-[12.1875rem] bg-[#3D8CFF] overflow-hidden" v-if="this.$route.name != 'mProduct'">
          <img src="@/assets/img/m/logo.png" class="w-[11.875rem] h-[.9375rem] mx-auto mt-[1.4375rem]"/>
          <ul class="w-full h-[1.25rem] flex justify-center items-center mt-[1.4375rem] mb-[1.25rem]">
            <li class="w-[25%] h-full text-[.875rem] text-[#fff] text-center relative" :style="{opacity:navIndex == index?'50%':'100%'}" v-for="(item,index) in navdata" :key="index" @click="activeNav(index)">{{ item.name }}<p class="w-[.0625rem] h-[.8125rem] bg-[#fff] opacity-[19%] absolute right-0 top-[50%] mt-[-0.375rem]"></p></li>
          </ul>
          <p class="w-full text-center text-[.625rem] leading-[.875rem] text-[#fff] font-normal mb-[.625rem]" @click="activeurl('https://beian.miit.gov.cn/')"><span>沪ICP备16045192</span></p>
          <p class="w-full text-center text-[.625rem] leading-[.875rem] text-[#fff] font-normal mb-[.625rem] flex justify-center" @click="activeurl('https://www.beian.gov.cn/')"><img src="@/assets/img/pc/gh.png" class="w-[.9375rem] h-[.9375rem] mr-[.375rem]"/><span>沪公网安备31010502004605</span></p>
          <p class="w-full text-center text-[.625rem] leading-[.875rem] text-[#fff] font-normal"><span>All Rights Reserved.明日世界（北京）科技有限公司 版权所有</span></p>
      </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      navIndex:0,
      isnav:false,
      // navdata:[{
      //   name:'关于我们'
      // },{
      //   name:'我们的产品'
      // },{
      //   name:'行知之路'
      // },{
      //   name:'加入我们'
      // }]
      navdata:[{
        name:'关于我们'
      },{
        name:'行知之路'
      },{
        name:'加入我们'
      }]
    }
  },
  methods:{
    activebtn(){
      this.isnav = !this.isnav
    },
    activeNav(index){
      this.navIndex = index
      this.isnav = false
      // if(this.navIndex == 0){
      //   this.$router.push({name:'mAboutUs'})
      // }else if(this.navIndex == 1){
      //   this.$router.push({name:'mProduct'})
      // }else if(this.navIndex == 2){
      //   this.$router.push({name:'mWay'})
      // }else if(this.navIndex == 3){
      //   this.$router.push({name:'mAdd'})
      // }
      if(this.navIndex == 0){
        this.$router.push({name:'mAboutUs'})
      }else if(this.navIndex == 1){
        this.$router.push({name:'mWay'})
      }else if(this.navIndex == 2){
        this.$router.push({name:'mAdd'})
      }
     
    },
    activeurl(url){
      window.location.href=url
    }
  }
}
</script>
<style scoped>
.borderli{
  border-bottom: .0625rem solid #DEDEDE;
}
.navlist{
  height: calc(100vh - 50px);
}
</style>