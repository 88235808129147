import { createRouter, createWebHistory } from 'vue-router'
import index  from '../views/index.vue'
import indexmb  from '../views/mobile/index.vue'

const routerpc = [
  {
    path: '/',
    name: 'index',
    component: index,
    children:[{
      path: '',
      name:'aboutUs',
      component: () =>import('../views/aboutUs.vue')
    },{
      path: '/add',
      name:'add',
      component: () =>import('../views/add.vue')
    },{
      path: '/way',
      name:'way',
      component: () =>import('../views/way.vue')
    },{
      path: '/product',
      name:'product',
      component: () =>import('../views/product.vue')
    }]
  }
]

const routermb = [
  {
    path: '/',
    name: 'indexmb',
    component: indexmb,
    children:[{
      path: '',
      name:'mAboutUs',
      component: () =>import('../views/mobile/aboutUs.vue')
    },{
      path: '/m/way',
      name:'mWay',
      component: () =>import('../views/mobile/way.vue')
    },{
      path: '/m/add',
      name:'mAdd',
      component: () =>import('../views/mobile/add.vue')
    },{
      path: '/m/product',
      name:'mProduct',
      component: () =>import('../views/mobile/product.vue')
    }]
  },
]
let routes = []
if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  routes = routermb
} else {
  routes = routerpc
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  // console.log('移动端不缩放')
  const baseSize = 16
  // // 设置 rem 函数
  function setRem() {
    // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
    const scale = document.documentElement.clientWidth / 375
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
  }
  setRem()
}else{
  // router.afterEach((to, from) => {
  //   // 基准
  //   const baseSize = 16
  //   // 设置 rem 函数
  //   function setRem() {
  //     // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
  //     const scale = document.documentElement.clientWidth / 1920
  //     // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
  //     document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
  //   }
  //   setRem()
  // })
}
export default router
