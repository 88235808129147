import { createStore } from 'vuex'

export default createStore({
  state: {
    count: 0
  },
  getters: {
  },
  mutations: {
    navindex(state,index){
      state.count = index;
    }
  },
  actions: {
  },
  modules: {
  }
})
