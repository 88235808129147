
<template>
  <div class="w-full h-min-[100vh]">
    <div class="w-full h-[6.25rem] bg-[#3D8CFF] fixed left-0 top-0 z-[99]" ref="nav" id="headerid">
        <div class="h-[6.25rem] absolute left-[8%] top-0">
            <img src="@/assets/img/pc/logo.png" class="w-[20.3125rem] h-[1.625rem] mt-[2.3125rem]"/>
        </div>
        <div class="absolute h-full right-[8%] 2xl:right-[8%]">
          <ul  class=" h-full flex justify-center items-center">
            <li class="navbottom text-[1rem] 2xl:text-[1rem] text-[#fff] ml-[3.75rem] cursor-pointer" v-for="(item,index) in navList" :key="index" :style="{opacity:navindex == index?'1':'0.8'}" @click="navActive(index)">{{ item.name }}</li>
          </ul>
        </div>
    </div>
    <!-- page -->
    <div class="w-full mt-[6.25rem]"> 
      <RouterView />
    </div>
    <!-- 底部信息 -->
    <div class="w-full h-[14.125rem] bg-[#3D8CFF]" v-if="this.$route.name != 'product'">
        <div class="w-full flex justify-between items-center mx-auto">
          <div class="ml-[8%] mt-[3.1875rem]">
            <img src="@/assets/img/pc/logo.png" class="w-[20.3125rem] h-[1.625rem]"/>
            <!-- <p class="text-[.8125rem] h-[1.125rem] text-[#fff] mt-[2.9375rem]"><span class="mr-[1.5625rem]">沪公网安备31010502004605</span><span>沪ICP备16045192</span></p> -->
            <p class="text-[.8125rem] h-[1.125rem] text-[#fff] mt-[2.5rem]">All Rights Reserved.明日世界（北京）科技有限公司 版权所有</p>
          </div>
          <div class="mt-[3.1875rem] mr-[8%]">
            <ul  class="flex justify-center items-center">
              <li class="navbottom text-[1rem] text-[#fff] px-[1.25rem] relative cursor-pointer" v-for="(item,index) in navList" :key="index" :style="{opacity:navindex == index?'0.8':'1'}" @click="navActive(index)">{{ item.name }}<p class="w-[.0625rem] h-[.8125rem] bg-[#fff] opacity-[19%] absolute right-0 top-[50%] mt-[-0.375rem]"></p></li>
            </ul>
            <p class="text-[.8125rem] h-[1.125rem] text-[#fff] mt-[2.9375rem] text-right cursor-pointer" @click="activeurl('https://beian.miit.gov.cn/')">京ICP备2022024502号-3</p>
            <p class="text-[.8125rem] h-[1.5625rem] text-[#fff] mt-[1rem] text-right cursor-pointer flex justify-end" @click="activeurl('https://www.beian.gov.cn/')"><img src="@/assets/img/pc/gh.png" class="w-[1.5rem] h-[1.5rem] mr-[.375rem]"/><span>京公网安备11010802042948号</span></p>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
// import request from '../../utils/request'
export default {
  data(){
    return{
      // navList:[{
      //   name:'关于我们'
      // },{
      //   name:'我们的产品'
      // },{
      //   name:'行知之路'
      // },{
      //   name:'加入我们'
      // }],
      navList:[{
        name:'关于我们'
      },{
        name:'行知之路'
      },{
        name:'加入我们'
      }],
      navindex:0
    }
  },
  mounted(){
    this.navindex =this.$store.state.count
  },
  methods:{
    navActive(index){
      this.navindex = index
      localStorage.setItem('navIndex',this.navindex);
      // if(this.navindex == 0){
      //   this.$router.push({name:'aboutUs'})
      // }else if(this.navindex == 1){
      //   this.$router.push({name:'product'})
      // }else if(this.navindex == 2){
      //   this.$router.push({name:'way'})
      // }else if(this.navindex == 3){
      //   this.$router.push({name:'add'})
      // }
      if(this.navindex == 0){
        this.$router.push({name:'aboutUs'})
      }else if(this.navindex == 1){
        this.$router.push({name:'way'})
      }else if(this.navindex == 2){
        this.$router.push({name:'add'})
      }
      this.$store.commit('navindex',this.navindex)
    },
    activeurl(url){
      window.open(url)
    }
  }
}
</script>
<style scoped>
.navbottom:last-child{
  padding-right:0;
}
.navbottom:hover{
  color: #8BFAFF;
}
</style>